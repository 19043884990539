import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { bootstrap } from "@/bootstrap";

import '@/main.scss'

const app = createApp(App)

bootstrap(app)

app.use(store)
    .use(router)
    .mount("#app")


