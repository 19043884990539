import { App } from "vue";
import { createStore } from "vuex";
import { storeInjectionKey } from "@/keys";
import { authStore } from '@/store/modules/auth'
import { webPushStore } from "@/store/modules/webPush";

import { categoriesStore } from "@/store/modules/shopping/silpo/categories";
import { citiesStore } from '@/store/modules/shopping/silpo/cities'
import { favouriteStoresStore } from "@/store/modules/shopping/silpo/favouriteStores";
import { productPricesStore } from "@/store/modules/shopping/silpo/productPrices";
import { productsStore } from "@/store/modules/shopping/silpo/products";
import { storesStore } from "@/store/modules/shopping/silpo/stores";

export const store = createStore({
    modules: {
        auth: authStore,
        webPush: webPushStore,
        shopping: {
            namespaced: true,
            modules: {
                silpo: {
                    namespaced: true,
                    modules: {
                        categories: categoriesStore,
                        cities: citiesStore,
                        favouriteStores: favouriteStoresStore,
                        productPrices: productPricesStore,
                        products: productsStore,
                        stores: storesStore,
                    }
                }
            }
        }
    }
})

export default {
    install (app: App): void {
        app.use(store)
        app.provide(storeInjectionKey, store)
    }
}