import { ListResponse, Paging, Product, Query } from '@/types/api'
import { silpoProductsApi } from '@/di/api'
import { Module } from "vuex";

interface ProductsQuery extends Query {
    filter: {
        favourite: boolean | null,
    },
    paging: Paging
}

interface ProductsStore {
    isLoading: boolean,
    items: Product[],
    total_count: number,
    query: ProductsQuery,
}

export const productsStore: Module<ProductsStore, null> = {
    namespaced: true,
    state: {
        isLoading: false,
        items: [],
        total_count: 0,
        query: {
            filter: {
                favourite: null
            },
            paging: {
                page: 1,
                per_page: 10
            }
        }
    },
    mutations: {
        setIsLoading (state, status: boolean) {
            state.isLoading = status
        },
        setData (state, { items, total_count }: ListResponse<Product>) {
            state.items = items
            state.total_count = total_count
        },
        setQuery (state, query: ProductsQuery) {
            state.query = query
        }
    },
    actions: {
        async load (context) {
            if (context.state.isLoading) {
                return
            }

            context.commit('setIsLoading', true)
            try {
                context.commit('setData', await silpoProductsApi.getProducts(context.state.query))
            } finally {
                context.commit('setIsLoading', false)
            }
        },
        async updateQuery (context, query: ProductsQuery) {
            context.commit('setQuery', query)
            await context.dispatch('load')
        }
    },
    getters: {
        items: (state): ListResponse<Product> => ({
            items: state.items,
            total_count: state.total_count
        }),
        isLoading: (state): boolean => state.isLoading
    }
}
