import { App, Component } from "vue";

import FormKitAutocomplete from '@/components/app/FormKit/FormKitAutocomplete.vue'

import { createInput, defaultConfig, plugin } from '@formkit/vue'
import { formKitInjectionKey } from "@/keys";

export default function (app: App): void {
    app.use(plugin, defaultConfig({
        inputs: {
            autocomplete: createInput(FormKitAutocomplete as unknown as Component, {
                props: ['fetchSuggestions', 'disableAutocomplete']
            })
        },
        config: {
            classes: {
                label: 'form-label',
                help: 'small',
                wrapper: '',
                outer (element) {
                    switch (element.props.type){
                    case 'checkbox':
                        return 'form-check'
                    default:
                        return ''
                    }

                    return element.props.type === 'checkbox'
                        ? 'mb-3 form-check'
                        : 'mb-3'
                },
                input (element) {
                    switch (element.props.type) {
                    case 'submit':
                        return ''
                    case 'checkbox':
                        return 'form-check-input'
                    case 'select':
                        return 'form-select'
                    default:
                        return 'form-control'
                    }
                },
                messages (element) {
                    return element.props.type === 'form'
                        ? 'd-none'
                        : 'list-unstyled'
                },
                message: 'text-danger small'
            }
        }
    }))

    app.provide(formKitInjectionKey, app.config.globalProperties.$formKit)
}