import Axios from '@/di/axios'
import { City, FavouriteStore, ListResponse, Query, Store, Uuid } from '@/types/api'

export const EVENT_NAME__FAVOURITE_STORE_CREATED = 'app.shopping.silpo.favourite_store.created'
export const EVENT_NAME__FAVOURITE_STORE_DELETED = 'app.shopping.silpo.favourite_store.deleted'

export default class StoresApi {
    private readonly axios: typeof Axios = Axios

    getCities (query: Query = {}): Promise<ListResponse<City>> {
        return this.axios.get('/api/front/shopping/silpo/cities', { params: query })
            .then((response) => response.data)
    }

    getStores (query: Query = {}): Promise<ListResponse<Store>> {
        return this.axios.get('/api/front/shopping/silpo/stores', { params: query })
            .then((response) => response.data)
    }

    getFavouriteStores (): Promise<ListResponse<FavouriteStore>> {
        return this.axios.get('/api/front/shopping/silpo/favourite-stores')
            .then((response) => response.data)
    }

    addFavouriteStore (filialId: number): Promise<void> {
        return this.axios.post('/api/front/shopping/silpo/favourite-stores', { filial_id: filialId })
            .then()
    }

    deleteFavouriteStore (id: Uuid): Promise<void> {
        return this.axios.delete(`/api/front/shopping/silpo/favourite-stores/${id}`)
            .then()
    }
}
