export const extraFieldTypes: { type: string, name: string, icon: string }[] = [
    {
        type: 'price',
        name: 'Price',
        icon: 'coins'
    },
    {
        type: 'distance',
        name: 'Distance',
        icon: 'arrows-alt-h'
    },
    {
        type: 'volume',
        name: 'Volume',
        icon: 'flask'
    },
    {
        type: 'geolocation',
        name: 'Geolocation',
        icon: 'map-marker-alt'
    },
    {
        type: 'photos',
        name: 'Photos',
        icon: 'images'
    }
]
