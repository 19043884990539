<template>
  <div>
    <h3>
      <slot/>
    </h3>
    <hr>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>