<template>
  <div class="page-size-marker">
    <span class="d-block d-sm-none">XS</span>
    <span class="d-none d-sm-block d-md-none">SM</span>
    <span class="d-none d-md-block d-lg-none">MD</span>
    <span class="d-none d-lg-block d-xl-none">LG</span>
    <span class="d-none d-xl-block">XL</span>
  </div>
</template>

<style scoped>
.page-size-marker {
  font-size: 0.7em;
  position: absolute;
  top: 54px;
  right: 2px;
  z-index: 9999;
  background-color: transparent;
  border-radius: 4px;
  padding: 2px;
  color: white;
}
</style>