import { App } from "vue";
import { store } from "@/store";

import initFormKit from '@/bootstrap/vendor/formkit'
import initFontAwesome from '@/bootstrap/vendor/fontawesome'
import initVueEasyLightBox from '@/bootstrap/vendor/vueEasyLightBox'
import initNotification from '@/bootstrap/vendor/notification'

import '@/bootstrap/vendor/bootstrap.ui'
import '@/bootstrap/vendor/chartjs'

import '@/bootstrap/system/backendEvents'

export function bootstrap (app: App): void {
    initFormKit(app)
    initFontAwesome(app)
    initVueEasyLightBox(app)
    initNotification(app)

    Promise.all([
        store.dispatch('auth/init'),
        store.dispatch('webPush/init'),
    ]).then()
}