import { City } from '@/types/api'
import { cacheService } from '@/di/services'
import { CACHE_TTL__ONE_HOUR } from '@/services/CacheService'
import { silpoStoresApi } from '@/di/api'
import { AppBaseStore, StateStatus } from '@/store/type'
import { Module } from "vuex";

const CACHE_KEY__CITIES = '01G4DNA6CKNXBVC9PZ2A164WMW'

interface CitiesStore extends AppBaseStore {
    status: {
        init: StateStatus,
    },
    list: City[],
}

export const citiesStore: Module<CitiesStore, null> = {
    namespaced: true,
    state: {
        status: {
            init: StateStatus.FRESH,
        },
        list: []
    },
    mutations: {
        setInitStatus (state, status: StateStatus) {
            state.status.init = status
        },
        setList (state, list: City[]) {
            state.list = list
        }
    },
    actions: {
        init (context) {
            if ([StateStatus.IN_PROGRESS, StateStatus.FINISHED].includes(context.state.status.init)) {
                return
            }

            context.commit('setInitStatus', StateStatus.IN_PROGRESS)

            const query = { paging: { page: 1, per_page: 500 } }

            return cacheService
                .remember(CACHE_KEY__CITIES, CACHE_TTL__ONE_HOUR, () => {
                    return silpoStoresApi.getCities(query)
                })
                .then(({ items }) => {
                    items.sort((a, b) => a.name.localeCompare(b.name))

                    context.commit('setList', items)
                })
                .then(() => {
                    context.commit('setInitStatus', StateStatus.FINISHED)
                })
                .catch(() => {
                    context.commit('setInitStatus', StateStatus.ERROR)
                })
        }
    },
    getters: {
        list: (state) => state.list,
        storeByCityId: (state) => (cityId: number) => {
            let city

            try {
                state.list.forEach(c => {
                    if (c.city_id === cityId) {
                        city = c
                        throw new Error('Break foreach loop')
                    }
                })
            } catch (e) {
                // do nothing
            }

            return city
        }
    }
}
