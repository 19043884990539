import Axios from '@/di/axios'
import { TokenAwareResult } from '@/types/api'

export default class AuthApi {
    private readonly axios: typeof Axios = Axios

    loginEmailPassword(email: string, password: string): Promise<TokenAwareResult> {
        return this.axios.post(
            '/api/auth/email-password/login',
            { email, password },
            { clientFingerprinted: true, authless: true, isUrgent: true }
        ).then((response) => response.data)
    }

    registerEmailPass(email: string, password: string, inviteCode: string): Promise<TokenAwareResult> {
        return this.axios.post(
            '/api/auth/email-password/register',
            { email, password, invite_code: inviteCode },
            { clientFingerprinted: true, authless: true, isUrgent: true }
        ).then((response) => response.data)
    }

    refreshToken(): Promise<TokenAwareResult> {
        return this.axios.post(
            '/api/auth/refresh',
            undefined,
            { refresh: true, clientFingerprinted: true, isUrgent: true }
        ).then((response) => response.data)
    }
}
