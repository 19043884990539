import { ExtendedAxiosRequestConfig } from '@/types/app'
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import eventBus from '@/di/eventBus'
import {
    AUTH_JWT_TOKEN_EXPIRED,
    AUTH_JWT_TOKEN_NOT_REFRESHED,
    AUTH_JWT_TOKEN_REFRESHED,
    TokenExpiredEvent,
    TokenNotRefreshedEvent,
    TokenRefreshedEvent
} from '@/events'
import { TokenAwareResult } from '@/types/api'
import { guid } from '@/helpers/tools'
import { authApi } from "@/di/api";
import { LS_KEY_ACCESS_TOKEN } from "@/keys";

interface QueueItem {
    resolve: (value?: unknown) => void,
    reject: () => void,
    config: ExtendedAxiosRequestConfig,
}

class Queue {
    private readonly items: QueueItem[] = []

    push (item: QueueItem): void {
        this.items.push(item)
    }

    shift (): QueueItem | undefined {
        return this.items.shift()
    }
}

export default (axiosInstance: AxiosInstance) => {
    const queue = new Queue()

    let refreshing = false

    eventBus.$on<TokenExpiredEvent>(AUTH_JWT_TOKEN_EXPIRED, ({ eventId }: { eventId: string }): void => {
        authApi.refreshToken()
            .then((refreshTokenResult) => {
                eventBus.$emit<TokenRefreshedEvent>(AUTH_JWT_TOKEN_REFRESHED, { eventId, refreshTokenResult })
            })
            .catch((error: AxiosError) => {
                eventBus.$emit<TokenNotRefreshedEvent>(AUTH_JWT_TOKEN_NOT_REFRESHED, { eventId, error })
            })
    })

    axiosInstance.interceptors.request.use(
        (config: ExtendedAxiosRequestConfig): Promise<ExtendedAxiosRequestConfig> | ExtendedAxiosRequestConfig => {
            if (refreshing
                && !config.authless
                && !config.refresh
                && !config.retry
                && !config.queue
            ) {
                return new Promise((resolve, reject) => {
                    config.queue = true
                    queue.push({ resolve: resolve as (value?: unknown) => void, reject, config })
                })
            }

            return config
        }
    )

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error: AxiosError<{ message: string }>) => {
            if (error.response?.status !== 401 || error.response.data.message !== 'Expired JWT Token') {
                return Promise.reject(error)
            }

            const config: ExtendedAxiosRequestConfig = error.config

            if (refreshing) {
                config.retry = true
                return new Promise((resolve, reject) => {
                    queue.push({ resolve, reject, config })
                }).then((c) => {
                    return axiosInstance.request(c as AxiosRequestConfig)
                }).catch(() => {
                    return Promise.reject(error)
                })
            }

            refreshing = true

            return new Promise<TokenAwareResult>((resolve) => {
                const eventId = guid()

                eventBus.$once<TokenRefreshedEvent>(
                    AUTH_JWT_TOKEN_REFRESHED,
                    (event) => {
                        if (event.eventId === eventId) {
                            resolve(event.refreshTokenResult)
                        }
                    })

                eventBus.$emit<TokenExpiredEvent>(AUTH_JWT_TOKEN_EXPIRED, { eventId })
            }).then((refreshTokenResult: TokenAwareResult) => {
                localStorage.setItem(LS_KEY_ACCESS_TOKEN, refreshTokenResult.token)
            }).then(() => {
                refreshing = false
                config.retry = true
                const retry = axiosInstance.request(config)

                // eslint-disable-next-line no-constant-condition
                while (true) {
                    const queued = queue.shift()
                    if (!queued) break

                    queued.resolve(queued.config)
                }

                return retry
            }).catch(() => Promise.reject(error))
                .finally(() => {
                    refreshing = false
                })
        }
    )
}
