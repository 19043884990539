import { InjectionKey } from "vue";
import { FormKitVuePlugin } from "@formkit/vue";
import { Store } from "vuex";
import { Notify } from "@/types/app";

export const LS_KEY_ACCESS_TOKEN = '01G71XT1Y9YJDJSHH8WQZZ5MP8'

export const formKitInjectionKey = Symbol('FormKit injection key') as InjectionKey<FormKitVuePlugin>

export const storeInjectionKey = Symbol('Store injection key') as InjectionKey<Store<any>>

export const notifyInjectionKey = Symbol('Notify injection key') as InjectionKey<Notify>