import { ActionContext, Module } from "vuex";
import { AppBaseStore, StateStatus } from "@/store/type";
import firebase from "firebase/compat/app"
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
import { RegisterWebPushTokenData } from "@/types/api/user";
import { userProfilesApi } from "@/di/api";
import { clientInfo } from "@/di/services";
import eventBus from "@/di/eventBus";
import { AUTH_JWT_CURRENT_USER_PROFILE_LOADED, AUTH_JWT_PRE_LOGOUT, CurrentUserProfileLoaded } from "@/events";
import { AuthStore } from "@/store/modules/auth";

// @ts-ignore
const config = FCM_CONFIG //eslint-disable-line

interface WebPushStore extends AppBaseStore {
    isBrowserSupported: boolean,
    webPushSubscriptionManager: WebPushSubscriptionManager | null,
    webPushStatus: WebPushStatus
}

export enum WebPushStatus {
    DEFAULT = 'default',
    GRANTED = 'granted',
    DENIED = 'denied',
    PROMPT = 'prompt',
}

class WebPushSubscriptionManager {
    private readonly messaging

    constructor (context: ActionContext<WebPushStore, null>) {
        const app = firebase.initializeApp(config)

        this.messaging = getMessaging(app)

        onMessage(this.messaging, (message) => {
            console.debug('[message]', message)
            if (!message.data?.foreground) {
                return
            }

            const title = message.notification?.title
            const body = message.notification?.body
            const image = message.notification?.image

            if (!title || !body || !context.getters.isAuthenticated) {
                return;
            }

            navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js')
                .then((req) => req?.showNotification(title, { body, icon: image }))
        })

        navigator.permissions.query({ name: 'notifications' })
            .then(async (permission: PermissionStatus): Promise<void> => {
                permission.onchange = (event: Event): Promise<void> => {
                    // @ts-ignore
                    if ([WebPushStatus.DENIED, WebPushStatus.PROMPT, WebPushStatus.DEFAULT].includes(event.currentTarget.state)) {
                        return this.unregisterToken()
                    }

                    return Promise.resolve()
                }
            })
    }

    getWebPushStatus (): WebPushStatus {
        return window.Notification.permission as WebPushStatus
    }

    async getToken (): Promise<string | null> {
        const permission = await navigator.permissions.query({ name: 'notifications' })

        if (permission.state === WebPushStatus.GRANTED) {
            return getToken(this.messaging)
        }

        return Promise.resolve(null)
    }

    async subscribe (): Promise<WebPushStatus> {
        const permission = await window.Notification.requestPermission()

        switch (permission) {
        case 'denied':
            console.log('Permissions denied')
            break
        case 'granted':
            await this.registerToken()
            break
        }

        return Promise.resolve(permission as WebPushStatus)
    }

    async registerToken (): Promise<void> {
        const token = await getToken(this.messaging)

        const data: RegisterWebPushTokenData = {
            ...(() => {
                return {
                    browser: clientInfo.getBrowser(),
                    browser_version: clientInfo.getBrowserVersion(),
                    os: clientInfo.getOS(),
                    os_version: clientInfo.getOSVersion()
                }
            })(),
            token,
            fingerprint: await clientInfo.getFingerprint(),
            type: 'firebase'
        }

        await userProfilesApi.triggerEventWebPushTokenChangedOnBrowser(data)
    }

    async unregisterToken (): Promise<void> {
        const fingerprint = await clientInfo.getFingerprint()

        await userProfilesApi.triggerEventWebPushTokenRemovedFromBrowser(fingerprint)
    }
}

function setupEventListeners (context: ActionContext<WebPushStore, null>) {
    eventBus.$on<CurrentUserProfileLoaded<AuthStore, null>>(AUTH_JWT_CURRENT_USER_PROFILE_LOADED, async () => {
        const permission: PermissionStatus = await navigator.permissions.query({ name: 'notifications' })

        if (permission.state === WebPushStatus.GRANTED) {
            await context.state.webPushSubscriptionManager?.registerToken()
        }
    })

    eventBus.$on(AUTH_JWT_PRE_LOGOUT, (): void => {
        if (context.getters.getWebPushStatus() === WebPushStatus.GRANTED) {
            context.state.webPushSubscriptionManager?.unregisterToken()
        }
    })
}

export const webPushStore: Module<WebPushStore, null> = {
    namespaced: true,
    state: {
        status: {
            init: StateStatus.FRESH
        },
        isBrowserSupported: false,
        webPushSubscriptionManager: null,
        webPushStatus: WebPushStatus.DEFAULT,
    },
    mutations: {
        setInitStatus (state, status = StateStatus): void {
            state.status.init = status
        },
        setIsBrowserSupported (state, isBrowserSupported: boolean): void {
            state.isBrowserSupported = isBrowserSupported
        },
        setWebPushSubscriptionManager (state, webPushSubscriptionManager: WebPushSubscriptionManager): void {
            state.webPushSubscriptionManager = webPushSubscriptionManager
        },
        setWebPushStatus (state, webPushStatus: WebPushStatus): void {
            state.webPushStatus = webPushStatus
        }
    },
    actions: {
        async init (context): Promise<void> {
            eventBus.$on<CurrentUserProfileLoaded<AuthStore, null>>(AUTH_JWT_CURRENT_USER_PROFILE_LOADED, async () => {
                if ([StateStatus.IN_PROGRESS, StateStatus.FINISHED].includes(context.state.status.init)) {
                    return
                }

                context.commit('setInitStatus', StateStatus.IN_PROGRESS)

                const isBrowserSupported = await isSupported();
                context.commit('setIsBrowserSupported', isBrowserSupported)

                if (!isBrowserSupported) {
                    context.commit('setInitStatus', StateStatus.FINISHED)

                    return
                }

                const webPushSubscriptionManager = new WebPushSubscriptionManager(context);
                context.commit('setWebPushSubscriptionManager', webPushSubscriptionManager)

                setupEventListeners(context)

                context.commit('setWebPushStatus', await webPushSubscriptionManager.getWebPushStatus())
                context.commit('setInitStatus', StateStatus.FINISHED)
            })
        },
        async subscribe (context): Promise<void> {
            if (!context.getters.isSupported) {
                return
            }

            const webPushStatus = await context.state.webPushSubscriptionManager?.subscribe()

            if (webPushStatus) {
                context.commit('setWebPushStatus', webPushStatus)
            }
        }
    },
    getters: {
        isSupported (state): boolean {
            return state.isBrowserSupported
        },
        isInitialized (state): boolean {
            return state.status.init === StateStatus.FINISHED
        },
        getWebPushStatus (state): WebPushStatus {
            return state.webPushStatus
        },
        isAuthenticated (_state, _getters, _rootState, rootGetters): boolean {
            return rootGetters["auth/isAuthenticated"]
        }
    }
}