import * as lscache from 'lscache'

export const CACHE_TTL__ONE_HOUR = 60

export const CACHE_TTL__ONE_DAY = 60 * 24

export const CACHE_TTL__ONE_MONTH = 60 * 24 * 30

export default class CacheService {
    private readonly cache: LSCache = lscache

    constructor() {
        lscache.setBucket('v1')
        lscache.flushExpired()
    }

    set<T>(key: string, value: T, ttlMinutes: number) {
        this.cache.set(key, value, ttlMinutes)
    }

    remove(key: string) {
        this.cache.remove(key)
    }

    get<T>(key: string): T {
        return this.cache.get(key)
    }

    remember<T>(key: string, ttlMinutes: number, callback: () => Promise<T>) {
        return new Promise<T>(resolve => {
            const cached = this.cache.get(key)

            if (cached) {
                resolve(cached)

                return
            }

            callback()
                .then(result => {
                    this.cache.set(key, result, ttlMinutes)

                    return result
                })
                .then(resolve)
        })
    }

    cleanUp(): void {
        lscache.flush()
    }
}
