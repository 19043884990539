import Axios from '@/di/axios'
import { Date, ListResponse, Product, ProductInfo, ProductPrice, Query } from '@/types/api'

export const EVENT_NAME__PRODUCT_PRICES_LOADED = 'app.shopping_silpo_product_prises_loaded'
export const EVENT_NAME__FIX_UNCATEGORIZED_PRODUCT_CATEGORY_COMMAND_FINISHED = 'app.bus.commend.shopping.silpo.fix-incategorized-product-command-finished'

export default class ProductsApi {
    private readonly axios: typeof Axios = Axios

    getProducts(query: Query = {}): Promise<ListResponse<Product>> {
        return this.axios.get('/api/front/shopping/silpo/products', { params: query })
            .then((response) => response.data)
    }

    searchProductInfos(query: Query): Promise<ListResponse<ProductInfo>> {
        return this.axios.get('/api/front/shopping/silpo/product-infos', { params: query })
            .then((response) => response.data)
    }

    getProductPrices(query: Query = {}): Promise<ListResponse<ProductPrice>> {
        return this.axios.get('/api/front/shopping/silpo/product-prices', { params: query })
            .then((response) => response.data)
    }

    loadProductPrices(params: { lagerId: number, filialIds: number[], date: Date }): Promise<void> {
        return this.axios.post('/api/front/shopping/silpo/product-prices', {
            lager_id: params.lagerId,
            filial_ids: params.filialIds,
            date: params.date
        })
            .then((response) => response.data)
    }

    updateProductCategory(params: { lagerId: number, categoryId: number }): Promise<void> {
        return this.axios.post('/api/front/shopping/silpo/fix-uncategorized-products', {
            lager_id: params.lagerId,
            category_id: params.categoryId
        })
            .then((response) => response.data)
    }
}
