import Axios from '@/di/axios'
import { Group, ListResponse, Plant, PlantLatinNames, Query, Subgroup, Uuid } from '@/types/api'

export const EVENT_NAME__PLANT_TRANSLATED = 'app.plants.plant_translated'

export default class PlantsApi {
    private readonly axios: typeof Axios = Axios

    getGroups(): Promise<ListResponse<Group>> {
        return this.axios.get('/api/front/plant/groups', { authless: true })
            .then((response) => response.data)
    }

    getSubgroups(query: Query = {}): Promise<ListResponse<Subgroup>> {
        return this.axios.get('/api/front/plant/subgroups', { params: query, authless: true })
            .then((response) => response.data)
    }

    getPlants(query: Query = {}): Promise<ListResponse<Plant>> {
        return this.axios.get('/api/front/plant/plants', { params: query, authless: true })
            .then((response) => response.data)
    }

    getPlant(id: Uuid): Promise<Plant> {
        return this.axios.get(`/api/front/plant/plants/${id}`, { authless: true })
            .then((response) => response.data)
    }

    getPlantSpecificLatinNames(ids: Uuid[]): Promise<ListResponse<PlantLatinNames>> {
        return this.axios.get('/api/front/plant/plant-specific-latin-names', {
            params: { filter: { ids: ids.join(',') } },
            authless: true
        })
            .then((response) => response.data)
    }

    translatePlant(id: Uuid): Promise<void> {
        return this.axios.post(`/api/front/plant/plants/${id}/translate`)
    }
}
