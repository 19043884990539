import Axios from '@/di/axios'
import { AccountNotification, ListResponse, Uuid } from '@/types/api'

export const EVENT_NAME__ACCOUNT_NOTIFICATIONS_READ = 'app.notifications.account_notifications_read'

export default class AccountNotificationsApi {
    private readonly axios: typeof Axios = Axios

    getList (): Promise<ListResponse<AccountNotification>> {
        return this.axios.get('/api/front/notifications/account-notifications')
            .then((response) => response.data)
    }

    read (ids: Uuid[]): Promise<void> {
        return this.axios.post('/api/front/notifications/account-notifications/read', { ids })
            .then((response) => response.data)
    }
}
