import { EventBus } from "@/di/eventBus";
import { STORE_INITIALIZED, StoreInitializedEvent } from "@/events";

export function resultOrWaitForInitialization<T> (eventBus: EventBus, storeName: string, isInitialized: () => boolean, result: () => T): T | Promise<T> {
    if (isInitialized()) {
        return result()
    }

    return new Promise<T>((resolve) => {
        eventBus.$onceNamed<StoreInitializedEvent>(
            STORE_INITIALIZED,
            storeName,
            () => {
                resolve(result())
            }
        )
    })
}