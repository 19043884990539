import eventBus from '@/di/eventBus'
import { tokenStorage } from '@/di/services'

const url = encodeURIComponent(`/events/${tokenStorage.getTraceId()}`)

// @ts-ignore
const eventSource = new EventSource(`${MERCURE_URL}?topic=${url}`) //eslint-disable-line

eventSource.onmessage = event => {
    const data = JSON.parse(event.data)

    const eventName = data.event_name
    delete data.event_name

    eventBus.$emit(eventName, data)
}
