import Axios from '@/di/axios'
import { Cheque, ChequeLineItem, ListResponse, Query, Uuid } from '@/types/api'

export default class ChequesApi {
    private readonly axios: typeof Axios = Axios

    getCheques(query: Query): Promise<ListResponse<Cheque>> {
        return this.axios.get('/api/front/shopping/silpo/cheques', { params: query })
            .then((response) => response.data)
    }

    getChequeLines(chequeId: Uuid): Promise<ListResponse<ChequeLineItem>> {
        const query = {filter: {cheque_id: chequeId}}
        return this.axios.get('/api/front/shopping/silpo/cheque-lines', { params: query })
            .then((response) => response.data)
    }

    loadLastCheques(days: number): Promise<void> {
        return this.axios.post('/api/front/shopping/silpo/cheques', { days })
            .then((response) => response.data)
    }
}
