import { Location, StringKeyed } from '@/types/app'
import { Query } from '@/types/api'
import type { ParsedQs } from 'qs'
import * as qs from 'qs'
import { v4 } from 'uuid'
import { EventBus } from "@/di/eventBus";
import { Router } from "vue-router";

export interface PromiseParallelAction<T = any> {
    promise: () => Promise<T>,
    resolve?: (result: T) => void
}

interface PromiseParallelConfig {
    beforeAll?: () => void,
    afterEach?: () => void,
    afterAll?: () => void,
    afterAllDelay?: number,
    pending?: number,
}

interface GetCurrentLocationParam {
    enableHighAccuracy: boolean,
    timeout: number,
}

export function promiseParallel (actions: PromiseParallelAction[], {
    beforeAll,
    afterEach,
    afterAll,
    afterAllDelay,
    pending,
}: PromiseParallelConfig = {}): Promise<any> {
    return new Promise((resolve: (value?: any) => void) => {
        let notResolved = actions.length
        let pendingNow = 0

        beforeAll?.()

        if (actions.length === 0) {
            afterAll?.()
            return
        }

        const loopIteration = (): void => {
            if (pendingNow < (pending || actions.length)) {
                const action = actions.shift()
                if (undefined === action) {
                    return
                }

                pendingNow++

                const { promise: p, resolve: r } = action

                p()
                    .then((result) => {
                        r?.(result)

                        afterEach?.()

                        notResolved--

                        if (notResolved === 0) {
                            if (afterAll) setTimeout(afterAll, afterAllDelay || 500)
                            resolve()
                        }
                    })
                    .then(() => {
                        pendingNow--
                    })
                loopIteration()
            } else {
                setTimeout(loopIteration, 500)
            }
        }

        loopIteration()
    })
}

export const createAsyncActionPromise = (eventBus: EventBus, promise: () => Promise<any>, postAction: () => void, eventName: string) => {
    return new Promise(
        resolve => {
            const callback = () => {
                postAction()
                eventBus.$off(eventName, callback)
                resolve(null)
            }

            eventBus.$once(eventName, callback)

            promise().catch(() => {
                eventBus.$off(eventName, callback)
                resolve(null)
            })
        }
    )
}

export function guid (): string {
    const s4 = (): string => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export function uuid (): string {
    return v4()
}

export function getCurrentGeoLocation (param: GetCurrentLocationParam): Promise<Location> {
    return new Promise<Location>((resolve, reject) => {
        if (!('geolocation' in navigator)) {
            reject(new Error('Browser not supports geolocation'))
        }

        navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
                resolve({ lat: coords.latitude, lon: coords.longitude })
            },
            reject,
            { ...param, maximumAge: 0 }
        )
    })
}

export function fillQueryParamsFromQueryString<T extends Query> (template: T): T {
    const getQueryParams = (): ParsedQs => qs.parse(new URL(location.href).search.replace(/^\?/, ''))

    const fillFromSource = (source: StringKeyed, _template: StringKeyed): StringKeyed => {
        const result = {} as StringKeyed

        Object.keys(_template)
            .filter((prop) => Object.prototype.hasOwnProperty.call(_template, prop))
            .forEach((prop) => {
                if (undefined === source[prop]) {
                    result[prop] = _template[prop]
                } else if (typeof _template[prop] === 'object' && _template[prop] !== null) {
                    result[prop] = fillFromSource(source[prop], _template[prop])
                } else {
                    if (typeof source[prop] === 'string' && source[prop].length === 0) {
                        result[prop] = null
                    } else if (['true', 'false'].includes(source[prop])) {
                        result[prop] = source[prop] === 'true'
                    } else {
                        result[prop] = source[prop]
                    }
                }
            })

        return result
    }

    const filled: T = fillFromSource(getQueryParams(), template) as T

    filled.paging = {
        page: Number.parseInt('' + filled?.paging?.page),
        per_page: Number.parseInt('' + filled?.paging?.per_page)
    }

    return filled
}

export function promoteParamsToSearch (search: object, router: Router) {
    const url = new URL(location.href)

    router.push(`${url.pathname}?${qs.stringify(search)}`)
}

export function objectsIsEquals (a: object, b: object): boolean {
    return Object.entries(a).sort().toString() === Object.entries(b).sort().toString()
}

export function rgbToHex (r: number, g: number, b: number): string {
    const componentToHex = (c: number): string => {
        // @todo CHeck toString argument
        const hex = c.toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }

    return ['#', ...[r, g, b].map(componentToHex)].join('')
}

export function wait (delayMs: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, delayMs)
    })
}

export function updatePageTitle (subtitle?: string): void {
    document.title = ['Your calendar', subtitle].filter((_) => !!_).join(' | ')
}
