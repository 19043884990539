import { AppBaseStore, StateStatus } from "@/store/type";
import { ActionContext, Module } from "vuex";
import { UserProfile } from "@/types/api/user";
import { authApi, userProfilesApi } from "@/di/api";
import eventBus from "@/di/eventBus";
import {
    AUTH_JWT_CURRENT_USER_PROFILE_LOADED,
    AUTH_JWT_LOGGED_IN,
    AUTH_JWT_LOGGED_OUT,
    AUTH_JWT_PRE_LOGOUT,
    AUTH_JWT_TOKEN_NOT_REFRESHED,
    CurrentUserProfileLoaded,
    LoggedInEvent,
    STORE_INITIALIZED,
    StoreInitializedEvent
} from "@/events";
import { LS_KEY_ACCESS_TOKEN } from "@/keys";
import { EmailPasswordPair } from "@/types/api";
import { resultOrWaitForInitialization } from "@/store/lib";

export const STORE_NAME__AUTH = 'auth'

export enum UserRole {
    ACCESS_TO_ADMIN = 'ROLE_ACCESS_TO_ADMIN',
    FILE_MANAGER = 'ROLE_FILE_MANAGER',
    SILPO_CHEQUES_CUSTOMER = 'ROLE_SILPO_CHEQUES_CUSTOMER',
    SILPO_PRODUCTS_MANAGER = 'ROLE_SILPO_PRODUCTS_MANAGER',
    USER = 'ROLE_USER',
}

export interface AuthStore extends AppBaseStore {
    accessToken: string | null,
    userProfile: UserProfile | null,
    isAuthenticated: boolean,
}

const TIMEOUT__CLEANUP_ACCESS_TOKEN = 1000
const PERIODIC_UPDATE__LOAD_USER_PROFILE = 60 * 1000
const PERIODIC_UPDATE__CHECK_ACCESS_TOKEN = 1000

function setupEventListeners (context: ActionContext<AuthStore, null>): void {
    eventBus.$on(AUTH_JWT_LOGGED_OUT, () => {
        setTimeout(() => {
            localStorage.removeItem(LS_KEY_ACCESS_TOKEN)
        }, TIMEOUT__CLEANUP_ACCESS_TOKEN)
    })

    eventBus.$on(AUTH_JWT_TOKEN_NOT_REFRESHED, () => {
        context.commit('setInitStatus', StateStatus.ERROR)
        return context.dispatch('logout')
    });

    (() => {
        ([
            {
                name: 'store.auth.update_access_token_in_store',
                period: PERIODIC_UPDATE__CHECK_ACCESS_TOKEN,
                action () {
                    const accessToken = localStorage.getItem(LS_KEY_ACCESS_TOKEN) || null;

                    if (
                        !accessToken
                        && (
                            context.state.isAuthenticated
                            || context.state.userProfile
                        )
                    ) {
                        console.log('[store.auth.update_access_token_in_store]')

                        return context.dispatch('logout')
                    }
                }
            },
            {
                name: 'store.auth.reload_user',
                period: PERIODIC_UPDATE__LOAD_USER_PROFILE,
                action () {
                    return context.dispatch('auth/loadCurrentUserProfile', undefined, { root: true })
                }
            }
        ]).forEach((task) => {
            eventBus.$onceNamed(STORE_INITIALIZED, STORE_NAME__AUTH, () => {
                eventBus.$emitPeriodic(task.name, task.period)
            })

            eventBus.$on(AUTH_JWT_LOGGED_IN, () => {
                eventBus.$on(task.name, task.action)
            })

            eventBus.$on(AUTH_JWT_LOGGED_OUT, () => {
                eventBus.$off(task.name, task.action)
            })
        })
    })()
}

export const authStore: Module<AuthStore, null> = {
    namespaced: true,
    state: {
        status: {
            init: StateStatus.FRESH
        },
        accessToken: null,
        userProfile: null,
        isAuthenticated: false,
    },
    mutations: {
        setInitStatus (state, status: StateStatus): void {
            if (
                state.status.init !== status
                && status === StateStatus.FINISHED
            ) {
                eventBus.$emit<StoreInitializedEvent>(STORE_INITIALIZED, { name: STORE_NAME__AUTH })
            }

            state.status.init = status
        },
        setAccessToken (state, accessToken: string | null): void {
            state.accessToken = accessToken
        },
        setUserProfile (state, userProfile: UserProfile): void {
            state.userProfile = userProfile
        },
        resetUserProfile (state): void {
            state.userProfile = null
        },
        setIsAuthenticated (state, isAuthenticated: boolean): void {
            state.isAuthenticated = isAuthenticated
        }
    },
    actions: {
        async init (context): Promise<void> {
            if ([StateStatus.FINISHED, StateStatus.IN_PROGRESS].includes(context.state.status.init)) {
                return
            }

            context.commit('setInitStatus', StateStatus.IN_PROGRESS)

            setupEventListeners(context)

            const accessToken = localStorage.getItem(LS_KEY_ACCESS_TOKEN)
            context.commit('setAccessToken', accessToken || null)

            try {
                await context.dispatch('login')

                context.commit('setInitStatus', StateStatus.FINISHED)
            } catch (e) {
                context.commit('setInitStatus', StateStatus.ERROR)
            }

            return Promise.resolve()
        },
        async login (context, credentials: { email?: string, password?: string } = {}): Promise<void> {
            if (credentials.email && credentials.password) {
                const response = await authApi.loginEmailPassword(credentials.email, credentials.password)
                context.commit('setAccessToken', response.token)
                localStorage.setItem(LS_KEY_ACCESS_TOKEN, response.token)
            }

            await context.dispatch('loadCurrentUserProfile')

            if (context.state.userProfile) {
                eventBus.$emit<LoggedInEvent>(AUTH_JWT_LOGGED_IN, { userProfile: context.state.userProfile })
            }

            return Promise.resolve()
        },
        logout (context): Promise<void> {
            eventBus.$emit(AUTH_JWT_PRE_LOGOUT)

            context.commit('resetUserProfile')
            context.commit('setIsAuthenticated', false)

            eventBus.$emit(AUTH_JWT_LOGGED_OUT)

            return Promise.resolve()
        },
        async signUpWithEmailAndPassword (context, payload: EmailPasswordPair & { inviteCode: string }): Promise<void> {
            const response = await authApi.registerEmailPass(payload.email, payload.password, payload.inviteCode)

            localStorage.setItem(LS_KEY_ACCESS_TOKEN, response.token)

            await context.dispatch('loadCurrentUserProfile')

            return Promise.resolve()
        },
        async loadCurrentUserProfile (context): Promise<void> {
            const accessToken = localStorage.getItem(LS_KEY_ACCESS_TOKEN)
            if (accessToken) {
                const userProfile = await userProfilesApi.getCurrentUserInfo()

                eventBus.$emit<CurrentUserProfileLoaded<AuthStore, null>>(
                    AUTH_JWT_CURRENT_USER_PROFILE_LOADED,
                    { context, userProfile }
                )

                context.commit('setUserProfile', userProfile)
                context.commit('setIsAuthenticated', true)
            }
        }
    },
    getters: {
        getInitStatus (state): StateStatus {
            return state.status.init
        },
        isAuthenticated (state): Promise<boolean> | boolean {
            return resultOrWaitForInitialization(
                eventBus,
                STORE_NAME__AUTH,
                () => state.status.init === StateStatus.FINISHED,
                () => state.isAuthenticated && !!state.accessToken
            )
        },
        userProfile (state): UserProfile | null {
            return state.userProfile
        },
        roles (state, getters): UserRole[] {
            return resultOrWaitForInitialization(
                eventBus,
                STORE_NAME__AUTH,
                () => state.status.init === StateStatus.FINISHED,
                () => getters.userProfile?.extra?.roles || []
            )
        }
    }
}
