import * as moment from 'moment'
import * as numeral from 'numeral'
import { Currency } from '@/types/app'

export function capitalize (value: string): string {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function format_date (value: string, type: string): string {
    let format

    switch (type) {
    case 'short':
        format = 'DD.MM.YYYY'
        break
    case 'date_time':
        format = 'DD.MM.YYYY HH:mm'
        break
    default:
        return value
    }

    return moment(value).format(format)
}

export function format_price (value: string | number): string {
    return numeral(value).format('0.00')
}

export function format_currency (value: Currency): string {
    if (value === 'UAH') {
        return 'грн.'
    }

    return value
}

export function format_unit (value: string): string {
    if (value === 'кг') {
        return 1 + value
    }

    return value
}
