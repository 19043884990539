// @ts-ignore
import axios, { AxiosResponse } from 'axios'
import * as qs from 'qs'
import { ExtendedAxiosRequestConfig } from "@/types/app";
import { tokenStorage } from "@/di/services";
import eventBusInterceptor from '@/di/axios/interceptor/eventBus'
import fingerprintInterceptor from '@/di/axios/interceptor/fingerprint'
import accessTokenInterceptor from '@/di/axios/interceptor/jwt/access'
import refreshTokenInterceptor from '@/di/axios/interceptor/jwt/refresh'
import pendingInterceptor from "@/di/axios/interceptor/pending";

export default (() => {
    const axiosInstance = axios.create({
        // @ts-ignore
        baseURL: API_URL, //eslint-disable-line
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Page-Load-Id': '' + tokenStorage.getTraceId(),
        },
        paramsSerializer: params => qs.stringify(params),
    })

    pendingInterceptor(axiosInstance, 5)
    eventBusInterceptor(axiosInstance)
    fingerprintInterceptor(axiosInstance)
    accessTokenInterceptor(axiosInstance)
    refreshTokenInterceptor(axiosInstance)

    return {
        get: <T = any, R = AxiosResponse<T>> (url: string, config?: ExtendedAxiosRequestConfig): Promise<R> => axiosInstance.get<T, R>(url, config),
        post: <T = any, R = AxiosResponse<T>> (url: string, data?: any, config?: ExtendedAxiosRequestConfig): Promise<R> => axiosInstance.post<T, R>(url, data, config),
        delete: <T = any, R = AxiosResponse<T>> (url: string, config?: ExtendedAxiosRequestConfig): Promise<R> => axiosInstance.delete<T, R>(url, config),
        put: <T = any, R = AxiosResponse<T>> (url: string, data?: any, config?: ExtendedAxiosRequestConfig): Promise<R> => axiosInstance.put<T, R>(url, data, config),
        path: <T = any, R = AxiosResponse<T>> (url: string, data?: any, config?: ExtendedAxiosRequestConfig): Promise<R> => axiosInstance.patch<T, R>(url, data, config)
    }
})()