import { EventBus } from '@/di/eventBus'
import { Uuid } from '@/types/api'
import { promiseParallel, PromiseParallelAction } from '@/helpers/tools'
import { RouteLocationRaw, Router } from "vue-router";

export type BaseProps = { itemId?: Uuid }

export interface BaseLock {
    preparing: boolean,
    formSubmitting: boolean
}

export class FormManager<R, P extends BaseProps, L extends BaseLock> {
    private readonly eventName: string;

    constructor (
        private readonly name: string,
        private readonly router: Router,
        private readonly eventBus: EventBus,
        private readonly props: Readonly<P>,
        private readonly submitData: () => object,
        private readonly lock: L,
        private readonly performers: {
            prepareFormActions?: PromiseParallelAction[],
            resolveItem: (itemId: Uuid) => PromiseParallelAction<R>,
            create: (submitData: object) => Promise<void>,
            update: (id: Uuid, submitData: object) => Promise<void>,
        },
        private readonly events: {
            created?: string,
            updated?: string,
        },
        private readonly redirectUrl: RouteLocationRaw
    ) {
        this.eventName = 'app.front.form_submitted_%s'.replace(/%s/, this.name)
    }

    getSubmit (): () => void {
        return async (): Promise<void> => {
            const submitData = this.submitData()

            this.lock.formSubmitting = true

            this.eventBus.$once(this.eventName, (): void => {
                this.router.push(this.redirectUrl)
            })

            const emitFormSubmitted = (): void => {
                this.eventBus.$emit(this.eventName)
            }

            const isAsyncAction
                = (this.isCreate() && this.events.created)
                || (this.isUpdate() && this.events.updated)

            try {
                if (this.isCreate()) {
                    if (isAsyncAction) {
                        this.eventBus.$once(this.events.created as string, emitFormSubmitted)
                    }

                    await this.performers.create(submitData)
                } else {
                    if (isAsyncAction) {
                        this.eventBus.$once(this.events.updated as string, emitFormSubmitted)
                    }

                    await this.performers.update(this.props.itemId as string, submitData)
                }

                if (!isAsyncAction) {
                    this.eventBus.$emit(this.eventName)
                }
            } finally {
                this.lock.formSubmitting = false
            }
        }
    }

    isCreate (): boolean {
        return !this.props.itemId
    }

    isUpdate (): boolean {
        return !!this.props.itemId
    }

    async prepareForm (): Promise<void> {
        this.lock.preparing = true

        try {
            const actions = this.performers.prepareFormActions || []
            if (actions.length > 0) await promiseParallel(actions)

            if (this.isUpdate()) {
                await new Promise((resolve, reject) => {
                    const resolveItemPromiseParallelAction = this.performers.resolveItem(this.props.itemId as string)
                    resolveItemPromiseParallelAction.promise()
                        .then(resolveItemPromiseParallelAction.resolve)
                        .finally(resolve as () => void)
                        .finally(reject)
                })
            }
        } finally {
            this.lock.preparing = false
        }
    }
}
