<template>
  <div id="preload" class="preload">
    <div class="preload-container">
      <div class="row">
        <div class="col-md-4 d-none d-sm-block"/>
        <div class="col-md-4 d-flex justify-content-aroud preload-container-wrapper">
          <div class="align-self-center preload-container-content">
            <div class="text-center">Initializing...</div>
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                   role="progressbar"
                   style="width: 100%"
                   aria-valuenow="100"
                   aria-valuemin="0"
                   aria-valuemax="100"/>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-none d-sm-block"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.preload {
  position: relative;

  &-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    overflow: hidden;
    padding: 10px;

    > .row {
      height: 100%;
    }

    &-wrapper {
      height: 70%;
    }

    &-content {
      width: 500px;

      .progress {
        height: 2px !important;
      }
    }
  }
}
</style>