import Axios from '@/di/axios'
import { Uuid } from '@/types/api'

export default class UploadedFilesApi {
    private readonly axios: typeof Axios = Axios

    uploadFile(id: Uuid, file: any) {
        const formData: any = new FormData()
        formData.append('qquuid', id)
        formData.append('file', file)

        return this.axios.post('/api/front/uploaded-files', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    }

    removeUploadedFile(id: Uuid): Promise<void> {
        return this.axios.delete(`/api/front/uploaded-files/${id}`)
            .then((response) => response.data)
    }
}
