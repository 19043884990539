import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";

import AuthView from '@/views/auth/AuthView.vue'
import SignInView from '@/views/auth/SignInView.vue'
import SignUpView from '@/views/auth/SignUpView.vue'

import PlaygroundView from '@/views/PlaygroundView.vue'

import CabinetView from '@/views/CabinetView.vue'
import SettingsView from '@/views/cabinet/SettingsView.vue'
import AccountNotificationView from '@/views/cabinet/AccountNotificationsView.vue'

import PlantListView from '@/views/plants/PlantListView.vue'
import PlantInfoView from '@/views/plants/PlantInfoView.vue'

import RemindersListView from '@/views/reminders/RemindersListView.vue'
import ReminderCreateFormView from '@/views/reminders/ReminderCreateFormView.vue'
import ReminderEditFormView from '@/views/reminders/ReminderEditFormView.vue'

import CalendarsListView from '@/views/calendar/CalendarsListView.vue'
import CalendarCreateFormView from '@/views/calendar/CalendarCreateFormView.vue'
import CalendarEditFormView from '@/views/calendar/CalendarEditFormView.vue'

import EventsListView from '@/views/calendar/EventsListView.vue'
import EventsCreateFormView from '@/views/calendar/EventCreateFormView.vue'
import EventsEditFormView from '@/views/calendar/EventEditFormView.vue'

import SilpoChequesListView from '@/views/shopping/silpo/ChequesListView.vue'
import SilpoFavouriteStoresView from '@/views/shopping/silpo/FavouriteStoresView.vue'
import SilpoProductsSearchView from '@/views/shopping/silpo/ProductsSearchView.vue'
import SilpoProductsView from '@/views/shopping/silpo/ProductsView.vue'
import SilpoStatisticView from '@/views/shopping/silpo/StatisticView.vue'
import SilpoUncategorizedProductsView from '@/views/shopping/silpo/UncategorizedProductsView.vue'


import { store } from '@/store'
import { updatePageTitle } from "@/helpers/tools";

import { UserRole } from "@/store/modules/auth";

enum AuthenticationStatus {
    AUTHENTICATED = 'yes',
    NOT_AUTHENTICATED = 'no',
}

declare module 'vue-router' {
    interface RouteMeta {
        authenticationStatus?: AuthenticationStatus,
        userRole?: UserRole,
        pageTitle?: string,
    }
}

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthView,
        redirect: () => ({ name: 'signin' }),
        children: [
            {
                path: 'sign-in',
                name: 'signin',
                component: SignInView,
            },
            {
                path: 'sign-up',
                name: 'signup',
                component: SignUpView
            }
        ],
        meta: {
            authenticationStatus: AuthenticationStatus.NOT_AUTHENTICATED,
            pageTitle: 'Authentication'
        }
    },
    {
        path: "/help",
        name: "help",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "help" */ "../views/HelpView.vue"),
        meta: {
            pageTitle: 'Help'
        }
    },
    {
        path: '/play',
        name: 'playground',
        component: PlaygroundView,
        meta: {
            pageTitle: 'Playground'
        }
    },
    {
        path: '/cabinet',
        name: 'cabinet',
        component: CabinetView,
        meta: {
            pageTitle: 'Cabinet'
        }
    },
    {
        path: '/cabinet/settings',
        name: 'cabinet-settings',
        component: SettingsView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Settings'
        }
    },
    {
        path: '/cabinet/notifications',
        name: 'cabinet-account-notifications',
        component: AccountNotificationView,
        meta: {
            pageTitle: 'Account notifications,'
        }
    },
    {
        path: '/plants',
        name: 'plants-list',
        component: PlantListView,
        meta: {
            pageTitle: 'Plants'
        }
    },
    {
        path: '/plants/:id',
        name: 'plant-info',
        component: PlantInfoView,
        meta: {
            pageTitle: 'Plant'
        }
    },
    {
        path: '/cabinet/reminders',
        name: 'reminders-list',
        component: RemindersListView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Reminders',
        },
    },
    {
        path: '/cabinet/reminder/add',
        name: 'reminder-create',
        component: ReminderCreateFormView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Add reminder',
        }
    },
    {
        path: '/cabinet/reminder/edit/:id',
        name: 'reminder-edit',
        component: ReminderEditFormView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Edit reminder',
        }
    },
    {
        path: '/cabinet/calendars',
        name: 'calendars-list',
        component: CalendarsListView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Calendars',
        }
    },
    {
        path: '/cabinet/calendars/add',
        name: 'calendar-create',
        component: CalendarCreateFormView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Add calendar',
        }
    },
    {
        path: '/cabinet/calendars/edit/:id',
        name: 'calendar-edit',
        component: CalendarEditFormView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Edit calendar',
        }
    },
    {
        path: '/cabinet/events',
        name: 'events-list',
        component: EventsListView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Events',
        }
    },
    {
        path: '/cabinet/events/add',
        name: 'event-create',
        component: EventsCreateFormView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Add event',
        }
    },
    {
        path: '/cabinet/events/edit/:id',
        name: 'event-edit',
        component: EventsEditFormView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Edit event',
        }
    },
    {
        path: '/shopping/silpo/cheques',
        name: 'shopping-silpo-cheques',
        component: SilpoChequesListView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Silpo cheques',
            userRole: UserRole.SILPO_CHEQUES_CUSTOMER,
        }
    },
    {
        path: '/shopping/silpo/statistic',
        name: 'shopping-silpo-statistic',
        component: SilpoStatisticView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Silpo statistic',
            userRole: UserRole.SILPO_CHEQUES_CUSTOMER,
        }
    },
    {
        path: '/shopping/silpo/products',
        name: 'shopping-silpo-products',
        component: SilpoProductsView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Silpo products',
            userRole: UserRole.SILPO_CHEQUES_CUSTOMER,
        }
    },
    {
        path: '/shopping/silpo/products-search',
        name: 'shopping-silpo-products-search',
        component: SilpoProductsSearchView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Silpo products search',
            userRole: UserRole.SILPO_CHEQUES_CUSTOMER,
        }
    },
    {
        path: '/shopping/silpo/favourite-stores',
        name: 'shopping-silpo-favourite-stores',
        component: SilpoFavouriteStoresView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Silpo favourite stores',
            userRole: UserRole.SILPO_CHEQUES_CUSTOMER,
        }
    },
    {
        path: '/shopping/silpo/manual-fix-uncategorized-products',
        name: 'shopping-silpo-manual-fix-uncategorized-products',
        component: SilpoUncategorizedProductsView,
        meta: {
            authenticationStatus: AuthenticationStatus.AUTHENTICATED,
            pageTitle: 'Manual fix uncategorized products',
            userRole: UserRole.SILPO_PRODUCTS_MANAGER,
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeResolve(async (to) => {
    if (
        to.meta.authenticationStatus === AuthenticationStatus.AUTHENTICATED
        && !store.getters["auth/isAuthenticated"]
    ) {
        return { name: 'auth' }
    }

    if (
        to.meta.authenticationStatus === AuthenticationStatus.NOT_AUTHENTICATED
        && store.getters["auth/isAuthenticated"]
    ) {
        return { name: 'home' }
    }
})

router.beforeResolve(async (to) => {
    if (to.meta.userRole) {
        const roles = await store.getters["auth/roles"]

        if (!roles.includes(to.meta.userRole)) {
            return false
        }
    }
})

router.afterEach((to) => {
    updatePageTitle(to.meta.pageTitle)
})

router.afterEach(() => {
    (document.getElementById('collapseNavbar') as Element)?.classList?.remove('show')
})

export default router;
