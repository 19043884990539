import AuthApi from "@/api/AuthApi";
import UserProfilesApi from "@/api/UserProfilesApi";
import PlantsApi from "@/api/PlantsApi";
import AccountNotificationsApi from "@/api/AccountNotificationsApi";
import RemindersApi from "@/api/RemindersApi";
import CalendarsApi from "@/api/CalendarsApi";
import EventsApi from "@/api/EventsApi";
import UploadedFilesApi from "@/api/UploadedFilesApi";
import ChequesApi from "@/api/shopping/silpo/ChequesApi";
import ProductsApi from "@/api/shopping/silpo/ProductsApi";
import CategoriesApi from "@/api/shopping/silpo/CategoriesApi";
import StatisticsApi from "@/api/shopping/silpo/StatisticsApi";
import StoresApi from "@/api/shopping/silpo/StoresApi";

export const authApi = new AuthApi

export const userProfilesApi = new UserProfilesApi

export const accountNotificationsApi = new AccountNotificationsApi

export const plantsApi = new PlantsApi

export const remindersApi = new RemindersApi

export const calendarsApi = new CalendarsApi

export const eventsApi = new EventsApi

export const uploadedFilesApi = new UploadedFilesApi

export const silpoChequesApi = new ChequesApi()
export const silpoProductsApi = new ProductsApi()
export const silpoCategoriesApi = new CategoriesApi()
export const silpoStatisticsApi = new StatisticsApi()
export const silpoStoresApi = new StoresApi()
