export enum StateStatus {
    FRESH = 'fresh',
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished',
    ERROR = 'error',
}

export interface AppBaseStore {
    status: {
        init: StateStatus,
    }
}