import { ProductPrice } from '@/types/api'
import { Moment } from 'moment/moment'
import * as moment from 'moment'
import { silpoProductsApi } from '@/di/api'
import { Module } from "vuex";

interface ProductPricesForLager {
    lagerId: number,
    prices: ProductPrice[],
    begin: Moment,
    end: Moment,
}

interface ProductPriceStore {
    isLoading: boolean,
    prices: ProductPricesForLager[],
}

export const productPricesStore: Module<ProductPriceStore, null> = {
    namespaced: true,
    state: {
        isLoading: false,
        prices: []
    },
    mutations: {
        setPricesForLager (state, {
            lagerId,
            prices,
            begin,
            end
        }: ProductPricesForLager) {
            const found = state.prices.filter(data => data.lagerId === lagerId)[0]
            if (found) {
                found.begin = begin
                found.end = end
                found.prices = prices
            } else {
                state.prices.push({ lagerId, prices, begin, end })
            }
        },
        setIsLoading (state, status: boolean) {
            state.isLoading = status
        }
    },
    actions: {
        loadPricesForLager (context, lagerId: number) {
            context.commit('setIsLoading', true)

            const begin = moment().subtract(1, 'years').clone()
            const end = moment().clone()

            return silpoProductsApi.getProductPrices({
                filter: {
                    lager_id: lagerId,
                    begin: begin.format(),
                    end: end.format()
                }
            })
                .then((data) => context.commit('setPricesForLager', {
                    lagerId,
                    prices: data.items,
                    begin,
                    end
                }))
                .finally(() => {
                    context.commit('setIsLoading', false)
                })
        }
    },
    getters: {
        isLoading: (state) => state.isLoading,
        priceForLager: (state) => (lagerId: number) => state.prices.filter((priceData) => priceData.lagerId === lagerId)[0] || {}
    }
}
