import { ListResponse, Query, Uuid } from '@/types/api'
import { Ref } from 'vue'
import { fillQueryParamsFromQueryString, promoteParamsToSearch } from '@/helpers/tools'
import { EventBus } from '@/di/eventBus'
import { Notify } from '@/types/app'
import { Router } from "vue-router";

export interface BaseLock {
    itemsLoading: boolean,
    removingId?: Uuid | null,
}

export interface BaseData<R> {
    presentation: ListResponse<R>
}

export default class ListManager<R, M extends Query, L extends BaseLock, D extends BaseData<R>> {
    constructor (
        private readonly router: Router,
        private readonly notify: Notify,
        private readonly eventBus: EventBus,
        private readonly model: Ref<M>,
        private readonly lock: L,
        private readonly data: D,
        private readonly loadPresentation: (query: M) => Promise<ListResponse<R>>,
        private readonly performRemove: (itemId: Uuid) => Promise<void>,
        private readonly events: { removed?: string, notRemoved?: string },
        private readonly providerIdFromEvent: (event: object) => Uuid
    ) {
    }

    async prepare (): Promise<void> {
        this.model.value = fillQueryParamsFromQueryString(this.model.value)
        await this.loadItems()
    }

    async loadItems (): Promise<void> {
        this.lock.itemsLoading = true

        try {
            this.data.presentation = await this.loadPresentation(this.model.value)
            promoteParamsToSearch(this.model.value, this.router)
        } finally {
            this.lock.itemsLoading = false
        }
    }

    async removeItem (itemId: Uuid): Promise<void> {
        if (!confirm('Are you sure?')) {
            return
        }

        if (this.events.removed) {
            this.eventBus.$once(
                this.events.removed,
                ((id) => {
                    return async (event: object): Promise<void> => {
                        if (this.providerIdFromEvent(event) === id) {
                            await this.loadItems()
                            this.lock.removingId = null
                        }
                    }
                })(itemId)
            )
        }

        if (this.events.notRemoved) {
            this.eventBus.$once(
                this.events.notRemoved,
                ((id: Uuid) => {
                    return async (event: { reason: string }) => {
                        if (this.providerIdFromEvent(event) === id) {
                            this.notify({
                                group: 'info',
                                type: 'error',
                                text: event.reason
                            })

                            this.lock.removingId = null
                        }
                    }
                })(itemId)
            )
        }

        try {
            this.lock.removingId = itemId

            await this.performRemove(itemId)
        } finally {
            this.lock.removingId = null
        }
    }
}
