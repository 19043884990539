import { FormKitSchemaNode } from "@formkit/core";
import { NotificationChannel } from "@/types/api";

type NotificationChannelCreation = Omit<NotificationChannel, 'id'>

type ChannelData = { [key: string]: string }

export type Model = NotificationChannelCreation & ChannelData

interface Channel {
    name: string,
    title: string,
    valueTitle: string,
    additionalSchema: FormKitSchemaNode[],
    makeData: (model: Model) => ChannelData,
    creatable: boolean,
    deletable: boolean,
}

interface ChannelConfig {
    allowedChannels: Channel[],
    getChannelByName: (name: string) => Channel | undefined,
    getLabelForChannel: (name: string, defaultLabel: string) => string,
    getAdditionalSchema: (name: string) => FormKitSchemaNode[],
    makeCreationData: (model: Model) => NotificationChannelCreation,
}

export const channelsConfig: ChannelConfig = {
    allowedChannels: [
        {
            name: 'slack_app_channel_bot',
            title: 'Slack app bot',
            valueTitle: 'Key',
            additionalSchema: [
                {
                    $formkit: 'text',
                    name: 'channel',
                    placeholder: 'Channel name',
                    validation: [['required']]
                }
            ],
            makeData: (model) => {
                return {
                    channel_name: model.channel
                }
            },
            creatable: true,
            deletable: true
        },
        {
            name: 'webpush',
            title: 'WebPush',
            valueTitle: 'Key',
            additionalSchema: [],
            makeData: (model) => model,
            creatable: false,
            deletable: true
        },
        {
            name: 'account',
            title: 'Account',
            valueTitle: 'Key',
            additionalSchema: [],
            makeData: (model) => model,
            creatable: false,
            deletable: false
        },
        {
            name: 'telegram_bot',
            title: 'Telegram bot',
            valueTitle: 'Key',
            additionalSchema: [],
            makeData: (model) => model,
            creatable: false,
            deletable: false
        }
    ],
    getChannelByName (name): Channel {
        return this.allowedChannels.filter((ch: Channel) => ch.name === name)[0] || {}
    },
    getLabelForChannel (name, defaultLabel) {
        if (!name) {
            return defaultLabel
        }

        return this.getChannelByName(name)?.valueTitle || defaultLabel
    },
    getAdditionalSchema (name) {
        return this.getChannelByName(name)?.additionalSchema || []
    },
    makeCreationData (model: Model) {
        const result: NotificationChannelCreation = {
            name: model.name,
            type: model.type,
            value: model.value,
            data: {}
        }

        if (model.type) {
            result.data = (this.getChannelByName(model.type) as Channel).makeData(model)
        }

        return result
    }
}