import { AxiosInstance } from 'axios'
import eventBus from '@/di/eventBus'
import { REQUEST_ERROR, REQUEST_FINISH, REQUEST_START } from '@/events'

export default (axiosInstance: AxiosInstance): void => {
    axiosInstance.interceptors.request.use((config) => {
        eventBus.$emit(REQUEST_START)
        return config
    })

    axiosInstance.interceptors.response.use(
        (response) => {
            eventBus.$emit(REQUEST_FINISH)
            return response
        },
        (error) => {
            eventBus.$emit(REQUEST_ERROR, error)

            return Promise.reject(error)
        }
    )
}
