import Axios from '@/di/axios'
import { RegisterWebPushTokenData, UserProfile } from '@/types/api/user'
import { ListResponse, NotificationChannel, NotificationType, Uuid } from '@/types/api'
import { AxiosRequestConfig } from 'axios'

export const EVENT_NAME__USER_PROFILE_SETTINGS_CHANGED = 'app.user_profile_settings_changed'
export const EVENT_NAME__USER_NOTIFICATION_CHANNEL_CREATED = 'app.user_notification_channel_created'
export const EVENT_NAME__USER_NOTIFICATION_CHANNEL_REMOVED = 'app.user_notification_channel_removed'
export const EVENT_NAME__USER_NOTIFICATION_CHANNEL_TYPES_UPDATED = 'app.user_notification_channel_types_updated'

export default class UserProfilesApi {
    private readonly axios: typeof Axios = Axios

    triggerEventWebPushTokenChangedOnBrowser(data: RegisterWebPushTokenData): Promise<void> {
        return this.axios.post('/api/front/notifications/push', data, { isUrgent: true })
            .then((response) => response.data)
    }

    triggerEventWebPushTokenRemovedFromBrowser(fingerprint: string): Promise<void> {
        return this.axios.delete(`/api/front/notifications/push/${fingerprint}`, { isUrgent: true })
            .then((response) => response.data)
    }

    changePassword(password: string, passwordConfirm: string): Promise<void> {
        return this.axios.post('/api/auth/change-password', { password, password_confirm: passwordConfirm })
            .then((response) => response.data)
    }

    getCurrentUserInfo(): Promise<UserProfile> {
        return this.axios.get('/api/front/user-profile', { isUrgent: true })
            .then((response) => response.data)
    }

    getUserInfo(id: Uuid): Promise<UserProfile> {
        return this.axios.get(`/api/front/user-profile/${id}`, { isUrgent: true })
            .then((response) => response.data)
    }

    updateUserInfo(id: Uuid, { name, timezone }: { name: string, timezone: string }): Promise<void> {
        return this.axios.put(`/api/front/user-profile/${id}`, { name, timezone })
            .then((response) => response.data)
    }

    removeAccount(id: Uuid): Promise<void> {
        return this.axios.delete(`/api/front/user-profile/${id}`)
    }

    getNotificationChannels(): Promise<ListResponse<NotificationChannel>> {
        return this.axios.get('/api/front/notifications/notification-channels')
            .then((response) => response.data)
    }

    // @todo Try to specify payload structure
    addNotificationChannel(payload: object): Promise<void> {
        return this.axios.post('/api/front/notifications/notification-channels', payload)
            .then((response) => response.data)
    }

    removeNotificationChannel(id: Uuid): Promise<void> {
        return this.axios.delete(`/api/front/notifications/notification-channels/${id}`)
            .then((response) => response.data)
    }

    getNotificationTypes(): Promise<NotificationType[]> {
        return this.axios.get('/api/front/notifications/types')
            .then((response) => response.data)
    }

    getNotificationTypesForNotificationChannel(notificationChannelId: Uuid): Promise<string[]> {
        return this.axios.get(`/api/front/notifications/notification-channels/${notificationChannelId}/notification-types`)
            .then((response) => response.data)
    }

    updateNotificationTypesForNotificationChannel(notificationChannelId: Uuid, notificationTypes: string[]): Promise<void> {
        return this.axios.put(`/api/front/notifications/notification-channels/${notificationChannelId}/notification-types`, { notification_types: notificationTypes })
            .then((response) => response.data)
    }

    triggerTestNotification(notificationChannelId: Uuid): Promise<void> {
        return this.axios.post(`/api/front/notifications/notification-channels/${notificationChannelId}/trigger-test-notification`)
            .then((response) => response.data)
    }
}
