import Axios from '@/di/axios'
import { Category, CategoryTreeItem, ListResponse } from '@/types/api'

export default class CategoriesApi {
    private readonly axios: typeof Axios = Axios

    getCategoryTree(): Promise<ListResponse<CategoryTreeItem>> {
        return this.axios.get('/api/front/shopping/silpo/categories-tree')
            .then((response) => response.data)
    }

    getCategories(): Promise<ListResponse<Category>> {
        return this.axios.get('/api/front/shopping/silpo/categories')
            .then((response) => response.data)
    }
}
