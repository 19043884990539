import { GUID } from '@/types/app'
import { TokenAwareResult } from '@/types/api'
import { AxiosError } from 'axios'
import { ActionContext } from "vuex";
import { UserProfile } from "@/types/api/user";

export const REQUEST_START = 'request.start'
export const REQUEST_FINISH = 'request.finish'
export const REQUEST_ERROR = 'request.error'

export const PAGINATION_PAGE_CHANGED = 'pagination.page_changed'

export const SCROLL_TO_TOP = 'navigation.scroll_to_top'

export const WEB_PUSH_INIT = 'web_push.init'

export const AUTH_JWT_TOKEN_EXPIRED = 'auth.jwt_token.expired'
export const AUTH_JWT_PRE_LOGOUT = 'auth.jwt.pre_logout'
export const AUTH_JWT_LOGGED_OUT = 'auth.jwt.logged_out'

export interface Named {
    name: string
}

export const STORE_INITIALIZED = 'store.initialized'

export interface StoreInitializedEvent extends Named {
}

export interface TokenExpiredEvent {
    eventId: GUID
}

export const AUTH_JWT_TOKEN_REFRESHED = 'auth.jwt_token.refreshed'

export interface TokenRefreshedEvent extends TokenExpiredEvent {
    refreshTokenResult: TokenAwareResult
}

export const AUTH_JWT_TOKEN_NOT_REFRESHED = 'auth.jwt_token.not_refreshed'

export interface TokenNotRefreshedEvent extends TokenExpiredEvent {
    error: AxiosError,
}

export const AUTH_JWT_CURRENT_USER_PROFILE_LOADED = 'auth.jwt.current_user_profile_loaded'

export interface CurrentUserProfileLoaded<S, R> {
    context: ActionContext<S, R>,
    userProfile: UserProfile
}

export const AUTH_JWT_LOGGED_IN = 'auth.jwt.logged_in'

export interface LoggedInEvent {
    userProfile: UserProfile
}
