import { Module } from "vuex";
import { AppBaseStore, StateStatus } from "@/store/type";
import { Category, CategoryTreeItem } from "@/types/api";
import { silpoCategoriesApi } from "@/di/api";
import { CACHE_TTL__ONE_DAY } from "@/services/CacheService";
import { cacheService } from "@/di/services";

const CACHE_KEY__CATEGORIES = '01FJ54WP621X2B35CRW2FXZT58'

interface CategoriesStore extends AppBaseStore {
    status: {
        init: StateStatus,
    },
    categories: Category[],
    categoryTree: CategoryTreeItem[],
}

export const categoriesStore: Module<CategoriesStore, null> = {
    namespaced: true,
    state: {
        status: {
            init: StateStatus.FRESH,
        },
        categories: [],
        categoryTree: []
    },
    mutations: {
        setInitStatus (state, status: StateStatus) {
            state.status.init = status
        },
        setCategories (state, payload: { categories: Category[] }) {
            state.categories = payload.categories
        },
    },
    actions: {
        init (context) {
            if ([
                StateStatus.IN_PROGRESS,
                StateStatus.FINISHED,
                StateStatus.ERROR
            ].includes(context.state.status.init)) {
                return
            }

            context.commit('setInitStatus', StateStatus.IN_PROGRESS)

            return cacheService
                .remember(CACHE_KEY__CATEGORIES, CACHE_TTL__ONE_DAY, () => silpoCategoriesApi.getCategories())
                .then((list) => {
                    context.commit('setCategories', { categories: list.items })
                })
                .then(() => context.commit('setInitStatus', StateStatus.FINISHED))
                .catch(() => {
                    context.commit('setInitStatus', StateStatus.ERROR)
                })
        }
    },
    getters: {
        categories: (state) => state.categories,
        categoryPathById: (state) => (categoryId: number) => {
            let path: string[] = []

            // eslint-disable-next-line no-constant-condition
            while (true) {
                const category = state.categories.filter((c) => categoryId === c.id)[0]

                if (category) {
                    path = [category.name, ...path]
                }

                if (
                    !category
                    || !category.parent_id
                ) {
                    break
                }

                categoryId = category.parent_id
            }

            return path
        }
    }
}