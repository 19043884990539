import { Calendar, ListResponse, Uuid } from '@/types/api'
import Axios from '@/di/axios'

export const EVENT_NAME__CALENDAR_REMOVED = 'app.calendar.calendar.removed'
export const EVENT_NAME__CALENDAR_NOT_REMOVED = 'app.calendar.calendar.not_removed'

export type CalendarWrite = Omit<Calendar, 'id' | 'is_default'>

export default class CalendarsApi {
    private readonly axios: typeof Axios = Axios

    getCalendars(): Promise<ListResponse<Calendar>> {
        return this.axios.get('/api/front/calendar/calendars?paging[page]=1&paging[per_page]=100')
            .then((response) => response.data)
    }

    createCalendar(data: CalendarWrite): Promise<void> {
        return this.axios.post('/api/front/calendar/calendars', data)
            .then((response) => response.data)
    }

    getCalendar(id: Uuid): Promise<Calendar> {
        return this.axios.get(`/api/front/calendar/calendars/${id}`)
            .then((response) => response.data)
    }

    updateCalendar(id: Uuid, data: CalendarWrite): Promise<void> {
        return this.axios.put(`/api/front/calendar/calendars/${id}`, data)
            .then((response) => response.data)
    }

    removeCalendar(id: Uuid): Promise<void> {
        return this.axios.delete(`/api/front/calendar/calendars/${id}`)
            .then((response) => response.data)
    }
}
