import TokenStorage from "@/services/TokenStorage";
import ClientInfo from "@/services/ClientInfo";
import PlantsService from "@/services/PlantsService";
import CacheService from "@/services/CacheService";

export const tokenStorage = new TokenStorage

export const clientInfo = new ClientInfo

export const plantsService = new PlantsService

export const cacheService = new CacheService
