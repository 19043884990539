import { AxiosInstance } from 'axios'
import { ExtendedAxiosRequestConfig } from '@/types/app'
import { clientInfo } from '@/di/services'

export default (axiosInstance: AxiosInstance): void => {
    axiosInstance.interceptors.request.use(
        async (config: ExtendedAxiosRequestConfig) => {
            if (config.clientFingerprinted) {
                config.headers = {
                    ...config.headers || {},
                    'X-Client-Fingerprint': await clientInfo.getFingerprint()
                }
            }

            return config
        }
    )
}
