import { silpoStoresApi } from '@/di/api'
import eventBus from '@/di/eventBus'
import { AppBaseStore, StateStatus } from '@/store/type'
import { Module } from "vuex";
import { FavouriteStore } from "@/types/api";
import {
    EVENT_NAME__FAVOURITE_STORE_CREATED,
    EVENT_NAME__FAVOURITE_STORE_DELETED
} from "@/api/shopping/silpo/StoresApi";

interface FavouriteStoresStore extends AppBaseStore {
    status: {
        init: StateStatus,
        loading: StateStatus,
        updating: StateStatus,
    },
    ids: number[],
    favouriteStores: FavouriteStore[],
}

export const favouriteStoresStore: Module<FavouriteStoresStore, null> = {
    namespaced: true,
    state: {
        status: {
            init: StateStatus.FRESH,
            loading: StateStatus.FRESH,
            updating: StateStatus.FRESH
        },
        ids: [],
        favouriteStores: [],
    },
    mutations: {
        setInitStatus (state, status: StateStatus) {
            state.status.init = status
        },
        setLoadingStatus (state, status: StateStatus) {
            state.status.loading = status
        },
        setUpdatingStatus (state, status: StateStatus) {
            state.status.updating = status
        },
        setFavouriteStores (state, favouriteStores: FavouriteStore[]) {
            state.favouriteStores = favouriteStores
        }
    },
    actions: {
        init (context) {
            if ([StateStatus.IN_PROGRESS, StateStatus.FINISHED].includes(context.state.status.init)) {
                return
            }

            context.commit('setInitStatus', StateStatus.IN_PROGRESS)
            return context.dispatch('load')
                .then(() => {
                    context.commit('setInitStatus', StateStatus.FINISHED)
                })
                .catch(() => {
                    context.commit('setInitStatus', StateStatus.ERROR)
                })
        },
        load (context) {
            if (context.state.status.loading === StateStatus.IN_PROGRESS) {
                return false
            }

            context.commit('setLoadingStatus', StateStatus.IN_PROGRESS)

            return silpoStoresApi.getFavouriteStores()
                .then((list) => {
                    context.commit('setFavouriteStores', list.items)
                })
                .then(() => context.commit('setLoadingStatus', StateStatus.FINISHED))
                .then(() => true)
                .catch(() => context.commit('setLoadingStatus', StateStatus.ERROR))
        },
        create (_, filialId) {
            return silpoStoresApi.addFavouriteStore(filialId)
        },
        delete (context, filialId) {
            return silpoStoresApi.deleteFavouriteStore(context.state.favouriteStores.filter((fs) => fs.filial_id === filialId)[0].id)
        },
        toggleFavourite (context, id: number) {
            if (context.state.status.updating === StateStatus.IN_PROGRESS) {
                return
            }

            const isPresent = context.getters.ids.includes(id);

            const eventName = isPresent
                ? EVENT_NAME__FAVOURITE_STORE_DELETED
                : EVENT_NAME__FAVOURITE_STORE_CREATED

            const callback = async () => {
                await context.dispatch('load')
                context.commit('setUpdatingStatus', StateStatus.FINISHED)
            }

            eventBus.$on(eventName, callback)

            context.commit('setUpdatingStatus', StateStatus.IN_PROGRESS)

            return context.dispatch(isPresent ? 'delete' : 'create', id)
                .catch(() => {
                    context.commit('setUpdatingStatus', StateStatus.ERROR)
                    eventBus.$off(eventName, callback)
                })
        }
    },
    getters: {
        ids: (state) => state.favouriteStores.map(fs => fs.filial_id)
    }
}
