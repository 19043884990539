import { ClientJS } from 'clientjs'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default class ClientInfo {
    // @ts-ignore
    private readonly client: ClientJS;

    constructor () {
        // @ts-ignore
        this.client = new ClientJS()
    }

    getBrowser (): string {
        return this.client.getBrowser()
    }

    getBrowserVersion (): string {
        return this.client.getBrowserVersion()
    }

    getOS (): string {
        return this.client.getOS()
    }

    getOSVersion (): string {
        return this.client.getOSVersion()
    }

    getFingerprint (): Promise<string> {
        return FingerprintJS.load()
            .then((fp) => fp.get())
            .then((r) => r.visitorId)
    }
}
