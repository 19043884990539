import Axios from '@/di/axios'
import {
    ByCategoryGroupedStatisticItem,
    ByLagerGroupedStatisticItem,
    ByMonthGroupedStatisticItem,
    DateTimeBounds,
    ListResponse, Uuid
} from '@/types/api'

export default class StatisticsApi {
    private readonly axios: typeof Axios = Axios

    getByMonthGroupedStatistic (bounds: DateTimeBounds): Promise<ListResponse<ByMonthGroupedStatisticItem>> {
        const query = { filter: { from: bounds.from, till: bounds.till } }
        return this.axios.get('/api/front/shopping/silpo/by-month-grouped-statistics', { params: query })
            .then((response) => response.data)
    }

    getByCategoryGroupedStatistic (bounds: DateTimeBounds): Promise<ListResponse<ByCategoryGroupedStatisticItem>> {
        const query = { filter: { from: bounds.from, till: bounds.till } }
        return this.axios.get('/api/front/shopping/silpo/by-category-grouped-statistics', { params: query })
            .then((response) => response.data)
    }

    getByLagerGroupedStatistic (bounds: DateTimeBounds, rootCategoryId: Uuid): Promise<ListResponse<ByLagerGroupedStatisticItem>> {
        const query = { filter: { ...bounds, root_category_id: rootCategoryId } }
        return this.axios.get('/api/front/shopping/silpo/by-lager-grouped-statistics', { params: query })
            .then((response) => response.data)
    }
}
