import * as emitter from 'tiny-emitter/instance'
import { Named } from '@/events'

export interface EventBus {
    $on: <E = object>(eventName: string, callback: (payload: E) => void) => void,
    $once: <E = object>(eventName: string, callback: (payload: E) => void) => void,
    $onceNamed: <E extends Named>(eventName: string, name: string, callback: (payload: E) => void) => void,
    $off: <E = object>(eventName: string, callback?: (payload: E) => void) => void,
    $emit: <E = object>(eventName: string, payload?: E) => void,
    $emitPeriodic: <E = object>(eventName: string, period: number, payload?: E) => void,
}

const eventBus: EventBus = {
    $on: (eventName, callback) => emitter.on(eventName, callback),
    $once: (eventName, callback) => emitter.once(eventName, callback),
    $onceNamed: <E extends Named> (eventName: string, name: string, callback: (payload: E) => void): void => {
        emitter.on(eventName, (event: E) => {
            if (event.name === name) {
                callback(event)
                emitter.off(eventName, callback)
            }
        })
    },
    $off: (eventName, callback) => emitter.off(eventName, callback),
    $emit: (eventName, payload) => emitter.emit(eventName, payload),
    $emitPeriodic: (eventName, period: number, payload) => {
        setTimeout(function run () {
            emitter.emit(eventName, payload)
            setTimeout(run, period)
        }, period)
    },
}

export default eventBus
