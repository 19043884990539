import { ListResponse, Store } from '@/types/api'
import { cacheService } from '@/di/services'
import { CACHE_TTL__ONE_HOUR } from '@/services/CacheService'
import { silpoStoresApi } from '@/di/api'
import { AppBaseStore, StateStatus } from '@/store/type'
import { Module } from "vuex";

const CACHE_KEY__STORES = '01G4DNAPZSXT20GR4VQ55F02HK'

interface StoresStore extends AppBaseStore {
    status: {
        init: StateStatus,
    },
    list: Store[],
}

export const storesStore: Module<StoresStore, null> = {
    namespaced: true,
    state: {
        status: {
            init: StateStatus.FRESH,
        },
        list: []
    },
    mutations: {
        setInitStatus (state, status: StateStatus) {
            state.status.init = status
        },
        setList (state, list: Store[]) {
            state.list = list
        }
    },
    actions: {
        init (context) {
            if ([StateStatus.IN_PROGRESS, StateStatus.FINISHED, StateStatus.ERROR].includes(context.state.status.init)) {
                return
            }

            context.commit('setInitStatus', StateStatus.IN_PROGRESS)

            const query = { paging: { page: 1, per_page: 500 } }

            return cacheService
                .remember<ListResponse<Store>>(CACHE_KEY__STORES, CACHE_TTL__ONE_HOUR, () => silpoStoresApi.getStores(query))
                .then((response: ListResponse<Store>) => {
                    context.commit('setList', response.items)
                })
                .then(() => {
                    context.commit('setInitStatus', StateStatus.FINISHED)
                })
                .catch(() => {
                    context.commit('setInitStatus', StateStatus.ERROR)
                })
        }
    },
    getters: {
        list: (state) => state.list,
        storeByFilialId: (state) => (filialId: number) => {
            let store

            try {
                state.list.forEach((s) => {
                    if (s.filial_id === filialId) {
                        store = s
                        throw new Error('Break foreach loop')
                    }
                })
            } catch (e) {
                // do nothing
            }

            return store
        }
    }
}
