import Axios from '@/di/axios'
import { Event, GeolocationEventExtraField, ListResponse, Query, Uuid } from '@/types/api'

export const EVENT_NAME__EVENT_REMOVED = 'app.calendar.event.removed'
export const EVENT_NAME__EVENT_NOT_REMOVED = 'app.calendar.event.not_removed'

export type EventWrite = Omit<Event, 'id' | 'calendar'> & { calendar_id: Uuid | null }

export default class EventsApi {
    private readonly axios: typeof Axios = Axios

    getEvents (query: Query): Promise<ListResponse<Event>> {
        return this.axios.get('/api/front/calendar/events', { params: query })
            .then((response) => response.data)
    }

    createEvent (event: EventWrite): Promise<void> {
        return this.axios.post('/api/front/calendar/events', event)
            .then((response) => response.data)
    }

    getEvent (id: Uuid): Promise<Event> {
        return this.axios.get(`/api/front/calendar/events/${id}`)
            .then((response) => response.data)
    }

    updateEvent (id: Uuid, event: EventWrite) {
        return this.axios.put(`/api/front/calendar/events/${id}`, event)
            .then((response) => response.data)
    }

    removeEvent (id: Uuid): Promise<void> {
        return this.axios.delete(`/api/front/calendar/events/${id}`)
            .then((response) => response.data)
    }

    getEventNameSuggestions (query: string): Promise<string[]> {
        if (!query) return Promise.resolve([])

        const urlPrefix = '/api/front/calendar/events/name-suggestions/'
        const uniqueName = `${urlPrefix.replaceAll('/', '-').replace(/^-/, '')}`

        return this.axios.get(urlPrefix + query, { uniqueName })
            .then((response) => response.data)
    }

    getExtraFieldNameSuggestions (extraFieldType: string, query: string): Promise<string[]> {
        if (!query) {
            throw new Error('Query cannot be empty')
        }

        const url = '/api/front/calendar/event-extra-fields/name-suggestions'
        const uniqueName = `${url.replaceAll('/', '-').replace(/^-/, '')}-${extraFieldType}`

        return this.axios.get(`${url}/${extraFieldType}/${query}`, { uniqueName })
            .then((response) => response.data)
    }

    getEventExtraFieldGeolocationsUnique (): Promise<GeolocationEventExtraField[]> {
        return this.axios.get('/api/front/calendar/event-extra-fields/name-suggestions/unique-geolocations')
            .then((response) => response.data)
    }
}
