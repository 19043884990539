import { AxiosInstance } from 'axios'
import { ExtendedAxiosRequestConfig } from '@/types/app'
import { LS_KEY_ACCESS_TOKEN } from "@/keys";

export default (axiosInstance: AxiosInstance): void => {
    axiosInstance.interceptors.request.use(
        (config: ExtendedAxiosRequestConfig) => {
            if (config.authless) return config

            const accessToken = localStorage.getItem(LS_KEY_ACCESS_TOKEN)
            if (accessToken) {
                config.headers = {
                    ...config.headers || {},
                    'Authorization': `Bearer ${accessToken}`
                }
            } else {
                return Promise.reject(new Error())
            }

            return Promise.resolve(config)
        }
    )


}
