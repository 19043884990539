import { AxiosInstance } from "axios";
import { ExtendedAxiosRequestConfig } from "@/types/app";

export default (axiosInstance: AxiosInstance, countOfActiveAtSameTime: number): void => {
    let pendingNow = 0

    const incrementPendingNow = () => {
        pendingNow++
    }

    const decrementPendingNow = () => {
        pendingNow = Math.max(0, pendingNow - 1);
    }

    axiosInstance.interceptors.request.use(
        (config: ExtendedAxiosRequestConfig) => {
            if (config.isUrgent) return config

            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (pendingNow < countOfActiveAtSameTime) {
                        incrementPendingNow()
                        clearInterval(interval)
                        resolve(config)
                    }
                }, 100)
            })
        },
    )

    axiosInstance.interceptors.response.use(
        (response) => {
            if (!(response.config as ExtendedAxiosRequestConfig).isUrgent) {
                decrementPendingNow()
            }

            return response
        },
        (error) => {
            if (!(error.config as ExtendedAxiosRequestConfig).isUrgent) {
                decrementPendingNow()
            }

            return Promise.reject(error)
        }
    )
}