<template>
  <div class="is-under-development-notification">Service is under development</div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.is-under-development-notification {
  font-size: 0.8em;
  color: white;
  text-align: center;
  background-color: dodgerblue;
}
</style>