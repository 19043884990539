import { ListResponse, Query, Reminder, Uuid } from '@/types/api'
import Axios from '@/di/axios'

export const EVENT_NAME__REMINDER_CREATED = 'app.calendar.reminder.created'
export const EVENT_NAME__REMINDER_UPDATED = 'app.calendar.reminder.updated'
export const EVENT_NAME__REMINDER_REMOVED = 'app.calendar.reminder.removed'
export const EVENT_NAME__REMINDER_NOT_REMOVED = 'app.calendar.reminder.not_removed'
export const EVENT_NAME__REMINDER_RESOLVED = 'app.calendar.reminder.resolved'

export type ReminderWrite = Omit<Reminder, 'id' | 'is_resolved'>

export default class RemindersApi {
    private readonly axios: typeof Axios = Axios

    getReminders (query: Query = {}): Promise<ListResponse<Reminder>> {
        return this.axios.get('/api/front/reminders', { params: query })
            .then((response) => response.data)
    }

    getReminder (id: Uuid): Promise<Reminder> {
        return this.axios.get(`/api/front/reminders/${id}`)
            .then((response) => response.data)
    }

    createReminder (reminder: ReminderWrite): Promise<void> {
        return this.axios.post('/api/front/reminders', reminder)
            .then((response) => response.data)
    }

    updateReminder (id: Uuid, reminder: ReminderWrite): Promise<void> {
        return this.axios.put(`/api/front/reminders/${id}`, reminder)
            .then((response) => response.data)
    }

    removeReminder (id: Uuid): Promise<void> {
        return this.axios.delete(`/api/front/reminders/${id}`)
            .then((response) => response.data)
    }

    resolveReminder (id: Uuid): Promise<void> {
        return this.axios.post(`/api/front/reminders/${id}/resolve`)
            .then((response) => response.data)
    }
}
