import { Plant, SunnyInfo } from '@/types/api'

const images = require.context('@/assets/images/plant', false, /\.jpg/)

// May be unused
export const SUNNY_MAP = [
    {
        code: 'sunny',
        name: 'Sunny',
        imageSource: 'sunny-sun.jpg'
    },
    {
        code: 'semi-shaded',
        name: 'Semi shaded',
        imageSource: 'sunny-half-shade.jpg'
    },
    {
        code: 'shaded',
        name: 'Shaded',
        imageSource: 'sunny-shade.jpg'
    }
]

export const MIN_MAX_MAP = [
    {
        code: 'height',
        imageSource: 'height.jpg'
    },
    {
        code: 'distance',
        imageSource: 'distance.jpg'
    }
]

export default class PlantsService {
    buildPlantUrl ({ id }: Plant): string {
        return `/plants/${id}`
    }

    buildPlantImageUrls (plant: Plant): string[] {
        // @ts-ignore
        return plant.images.map(image => `${API_URL}/uploaded/images/${image}`) //eslint-disable-line
    }

    getSunnyInfos (): SunnyInfo[] {
        return [
            {
                code: 'sunny',
                name: 'Sunny',
                imageSource: 'sunny-sun.jpg'
            },
            {
                code: 'semi-shaded',
                name: 'Semi shaded',
                imageSource: 'sunny-half-shade.jpg'
            },
            {
                code: 'shaded',
                name: 'Shaded',
                imageSource: 'sunny-shade.jpg'
            }
        ].map(({ code, name, imageSource }: SunnyInfo): SunnyInfo => {
            return {
                code,
                name,
                imageSource: images(`./${imageSource}`)
            }
        })
    }

    getSunnyInfo (code: string): SunnyInfo {
        return this.getSunnyInfos().filter((s) => s.code === code)[0]
    }

    buildMinMaxImage (code: string): string {
        return images(`./${MIN_MAX_MAP.filter((mm) => mm.code === code)[0].imageSource}`)
    }
}
