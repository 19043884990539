<template>
  <div class="row">
    <div class="col-md"/>
    <div class="col-md">
      <router-view/>
    </div>
    <div class="col-md"/>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>

</style>